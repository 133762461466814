<template lang="pug">
	.main-wrapper.menu-listar
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-12.ta-right
					h1.text-header.text-secondary MedClub / <b>Menus</b>

		Dialog.dialogApagar(header='Remover menu' :visible.sync='dialogApagar' :modal='true')
			p Deseja remover o menu <b>{{ dialogApagar_data.nm_menu }}</b>?
			.ta-center.my-4
				ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
				Button.p-button-danger(v-else label='Remover' @click='remove()')
		
		.ta-right.mt-4.mb-4
			Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/menus/salvar/0/')")
		
		ProgressBar(v-if='waiting' mode="indeterminate")
		
		div(v-else-if='list.length == 0')
			p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
		div(v-else)

			Panel(header='Estrutura da aplicação')
				.menu-wrapper
					.menu-box(v-for='val, key in list' :key='key')
						p.menu-name(style='font-weight: 700')
							i.menu-icon(:class="val.nm_icone")
							span {{ val.nm_menu }}
						a.btn-editar(type='button' @click="$router.push(`/menus/salvar/${ val.id }/`)") <i class='jam jam-write'></i>
						a.btn-apagar(type='button' @click="dialogApagar = true; dialogApagar_data = val") <i class='jam jam-trash'></i>
						.menu-children(v-if='val.children' v-for='child, idx in val.children' :key='idx')
							div.graphics
							p.menu-name
								i.menu-icon(:class="child.nm_icone")
								span {{ child.nm_menu }}
							a.btn-editar(type='button' @click="$router.push(`/menus/salvar/${ child.id }/`)") <i class='jam jam-write'></i>
							a.btn-apagar(type='button' @click="dialogApagar = true; dialogApagar_data = child") <i class='jam jam-trash'></i>

		
</template>

<style lang="scss">
	.menu-listar {
		.dialogApagar {
			.p-progress-spinner {
				width: 29px;
				height: auto;
			}
		}
		.menu-wrapper {
			$menuWrapper_bgColor: #f8f8f8; border: 1px solid #c8c8c8; border-radius: 4px;
			margin: 10px auto; width: 50%; background-color: $menuWrapper_bgColor; padding: 20px;
			
			@media all and (max-width: 576px) {
				width: 100%;
			}
			.menu-box {
				font-size: 0;
				.menu-name, .btn-editar, .btn-apagar { display: inline-block; vertical-align: middle; }
				.menu-name { font-size: 13px; background-color: $menuWrapper_bgColor; position: relative;
					z-index: 10; padding-bottom: 10px; margin-bottom: 0; }
				.btn-editar, .btn-apagar {
					width: 30px; height: 30px; border: 0; margin-left: 8px; border-radius: 50%; color: #fff;
					text-align: center; line-height: 44px; cursor: pointer; box-shadow: 2px 2px 2px #bbb;
					i { font-size: 16px; }
				}
				.menu-icon { font-size: 16px; margin-right: 8px; }
				.btn-editar { background-color: #312c66; }
				.btn-apagar { background-color: #df3637; }
				.menu-children { margin-left: 60px; position: relative; z-index: 9; }
				.graphics { width: 40px; height: 50px; border-left: 2px solid #000; border-bottom: 2px solid #000;
					position: absolute; left: -46px; top: -26px; border-bottom-left-radius: 4px; }
			}
		}
	}
	.p-tooltip {
		max-width: none;
	}
</style>

<script>
	import ProgressBar from 'primevue/progressbar'
	import DataView from 'primevue/dataview'
	import Panel from 'primevue/panel'
	import Paginator from 'primevue/paginator'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import InputText from 'primevue/inputtext'
	import InputMask from 'primevue/inputmask'
	import Button from 'primevue/button'
	import Tooltip from 'primevue/tooltip'
	import Dialog from 'primevue/dialog'
	import ProgressSpinner from 'primevue/progressspinner'

	import { Menus } from './../../middleware'
	import wsConfigs from './../../middleware/configs'
	export default {
		created () { this.applyFilters() },
		components: { ProgressBar, DataView, Panel, Paginator, DataTable, InputMask,
			Column, Button, Tooltip, Dialog, ProgressSpinner, InputText },
		directives: { tooltip: Tooltip },
		data () {
			return {
				list: {},
				windowInnerWidth: window.innerWidth,
				waiting: false,
				waitingApagar: false,
				dialogApagar: false,
				dialogApagar_data: {},
				filters: {
					nr_cpf: '',
					nm_pessoa_fisica: ''
				},
				paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
					per_page: wsConfigs.paginator_perPage,
					count: 0
				}
			}
		},
		methods: {
			getList (params) {
				this.waiting = true
				return Menus.findAll(params).then(response => {
					if (response.status == 200) {
						response.data.forEach(menu => { this.list[menu.id] = menu })
						response.data.forEach(menu => {
							if (menu.cd_sub_menu) {
								if (! this.list[menu.cd_sub_menu].children) this.list[menu.cd_sub_menu].children = []
								this.list[menu.cd_sub_menu].children.push(this.list[menu.id])
								delete this.list[menu.id]
							}
						})
					}
					this.waiting = false
					return true
				})
			},
			checkEmptyField (field) {
				if (! this.filters[field].length) this.applyFilters()
			},
			applyFilters () {
				// let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
				// if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
				let params = {}
				Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key]})
				this.getList(params)
			},
			onPage (ev) {
				this.paginator.page = ev.page + 1
				this.applyFilters()
			},
			remove () {
				this.waitingApagar = true
				Menus.remove(this.dialogApagar_data.id).then(response => {
					this.waitingApagar = false
					if (([200, 201, 204]).includes(response.status)) {
						this.$toast.info('Menu removido com sucesso', { duration: 3000 })
						this.dialogApagar = false
						this.applyFilters()
					} else if (response.data.detail) {
						if (typeof response.data.detail == 'string')
							this.$toast.error(response.data.detail, { duration: 3000 })
						else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
					}
				})
			}
		}
	}
</script>